/* desktopview */

.set {
  height: 229px;
  width: 409px;
  display: inline-block;
  margin-right: 49px;
  transition: transform 0.3s ease-in-out;
}

.set:hover {
  transform: scale(1.05);
}

.deskp{
  padding-bottom: 7px;
}

.paused .animate-marquee {
  animation-play-state: paused;
}

.paused .animate-marquee2 {
  animation-play-state: paused;
}


/* mobileview */

@media (max-width: 600px) {
  .relative.flex.overflow-x-hidden {
    display: block;
  }

  .ms{
    margin-left: 29px;
  }
 

.deskp{
  padding-bottom: 29px;
}

  .animate-marquee,
  .animate-marquee2 {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    padding-top: 12px;
    width: max-content;
    margin: 0 auto;
    margin-bottom: 20px; /* Add some margin between the carousels */
  }

  .animate-marquee img,
  .animate-marquee2 img {
    flex: 0 0 auto;
    margin-right: 16px;
    height: 173px;
    width: 295px;

  }

  .animate-marquee img:last-child,
  .animate-marquee2 img:last-child {
    margin-right: 0;
  }

  .paused .animate-marquee,
  .paused .animate-marquee2 {
    animation-play-state: paused;
  }
}

/* tablet view */

@media (min-width: 769px) and (max-width: 1024px){
  .relative.flex.overflow-x-hidden {
    display: block;
  }
.onecard{
  margin-left: 28px;
}
  

  .animate-marquee,
  .animate-marquee2 {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    padding-top: 12px;
    width: max-content;
    margin: 0 auto;
    margin-bottom: 20px; /* Add some margin between the carousels */
  }

  .animate-marquee img,
  .animate-marquee2 img {
 
      flex: 0 0 auto;
      margin-right: 21px;
      height: auto;
      width: 390px;
  }
  

  .animate-marquee img:last-child,
  .animate-marquee2 img:last-child {
    margin-right: 0;
  }

  .paused .animate-marquee,
  .paused .animate-marquee2 {
    animation-play-state: paused;
  }

  .deskp{
    padding-bottom: 38px;
  }
}

/* imac view */
@media (min-width: 4480px) {
  .set {
    height: 696px;
    width: 1246px;
    display: inline-block;
    margin-right: 220px;
    transition: transform 0.3s ease-in-out;
}
.topb{
  font-size: 5rem;
  margin-top: 5%;
  padding-bottom: 5%;
}
}