/* desktop view */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.carousel-container {
  max-width: 100%;
  margin: 0 auto;
  margin-right: 20px;
  margin-top: 84px;
}

.carousel-image {
  height: 93px;
}

.wid {
  width: 0px;
}

.gio {
  width: 144px;
}

.slick-slider {
  margin-top: 18px;
}

.logos-slide img {
  height: 81px;
  margin-right: 20px;
}

.logos-slide img {
  height: 81px;
  margin-right: 10px;
}

.logos {
  height: 155px;
  margin-top: 9%;
  margin-bottom: 8%;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
}

.vtp {
  margin-right: 45px;
  margin-left: 56px;
}

.logos::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 307px;
  height: 150%;
  background-color: #eeeeee;
  z-index: 2;
  content: "Our Brand";
  color: #999995;
  font-size: 32px;
  padding: 21px;
  text-align: center;
  white-space: pre-wrap;
  font-weight: 600;
  padding-right: 17px;
  transform: translateY(2px);
  padding-left: 74px;
}

.logos::after {
  position: absolute;
  top: 60px;
  left: 0;
  width: 307px;
  height: 150%;
  background-color: #eeeeee;
  z-index: 2;
  content: "Partnerships";
  color: #f2833e;
  font-size: 32px;
  padding: 10px;
  text-align: center;
  white-space: pre-wrap;
  font-weight: 600;
  padding-left: 96px;
}

.logos-slide {
  display: inline-block;
  animation: 10s slide infinite linear;
  margin-top: 38px;
  margin: 0 5px;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* mobile view */
@media (max-width: 768px) {
  .carousel-container {
    max-width: 100%;
    margin-top: 70px;
  }

  .gio {
    width: 100%;
  }

  .logos-slide img {
    height: 60px;
  }

  .logos::after {
    position: absolute;
    top: 60px;
    left: 0;
    width: 152px;
    height: 65px;
    background-color: #eeeeee;
    z-index: 2;
    content: "Partnerships";
    color: #f2833e;
    font-size: 21px;
    padding: 10px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 600;
    padding-left: 18px;
  }

  .logos::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 152px;
    height: 65px;
    background-color: #eeeeee;
    z-index: 2;
    content: "Our Brand";
    color: #999995;
    font-size: 21px;
    padding: 31px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 600;
    padding-right: 9px;
    transform: translateY(2px);
    padding-left: 0px;
  }

  .logos-slide {
    display: inline-block;
    animation: 10s slide infinite linear;
    margin-top: 38px;
    margin: 0 5px;
  }

  .sideing {
    padding-left: 27px;
  }

  .lefting {
    /* margin-left: 33px; */
    margin-right: 53px;
  }

  .hiding {
    display: none;
  }

  .heightof {
    height: 79px;
  }

  .heightinn {
    height: 67px;
  }

  .adjust {
    margin-left: 88px;
  }
  .slick-slider {
    margin-top: 26px;
  }
}

/* desktop view */
@media (min-width: 769px) and (max-width: 1024px) {
  .logos {
    height: 155px;
    margin-top: 12%;
    margin-bottom: 14%;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    overflow-x: hidden;
  }
}


/* imac view */
@media (min-width: 4480px) {
  .logos::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 821px;
    height: 179%;
    background-color: #eeeeee;
    z-index: 2;
    content: "Our Brand";
    color: #999995;
    font-size: 113px;
    padding: 10px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 600;
    padding-right: 132px;
    transform: translateY(2px);
    padding-left: 74px;
  }

  .logos {
    height: 373px;
    margin-top: 11%;
    margin-bottom: 8%;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    overflow-x: hidden;
  }

  .logos::after {
    position: absolute;
    top: 126px;
    left: 0;
    width: 312px;
    height: 213%;
    background-color: #eeeeee;
    z-index: 2;
    content: "Partnerships";
    color: #f2833e;
    font-size: 113px;
    padding: 12px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 600;
    padding-left: 96px;
    margin-top: 20px;
  }

  .sethi {
    height: 283px;
    /* margin-left: 5rem; */
  }

  .shapur {
    margin-left: 16rem;
    height: 229px;
    margin-top: 34px;
  }
  .sahil {
    height: 189px;
    padding-left: 16%;
    margin-top: 57px;
  }
  .vtp {
    margin-left: 12rem;
  }
}
