/* dektop view */
.testimonials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 88vh;
  /* margin-top: 13px; */
}
.padb {
  padding-bottom: 39px;
}

.first-arrow {
  display: none;
}

.second-arrow {
  display: none;
}

.grayBackground {
  background-color: #f8f8f8;
  padding: 30%;
  text-align: center;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.video-container .slick-slide .video-slide {
  border-radius: 20px;
}

.horizontal-dots {
  position: absolute;
  /* bottom: 10px; */
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 102%;
}

div.video-slide {
  display: inline-block;
}

.video-slide iframe {
  border-radius: 30px;
}

.iframe {
  display: inline-block;
  vertical-align: middle;
}

.grayBackground .video-slide .youtube-container {
  border-radius: 20px;
}

.grayBackground .video-slide .youtube-container {
  border-radius: 20px !important;
}
.video-slide {
  border-radius: 20px !important;
}

.slick-dots li.slick-active button:before {
  color: #f2833e !important;
}

.slick-dots li button:before {
  color: #9b9292 !important;
}

/* mobile view */
@media (max-width: 767px) {
  .testimonials-container {
    height: auto;
    padding-top: 7%;
    padding-bottom: 12%;
    margin-top: 10px;
  }

  .first-arrow {
    position: absolute;
    top: 54%;
    left: 103%;
    transform: translateY(-50%);
    height: 20px;
    display: block;
    z-index: 1;
  }

  .second-arrow {
    position: absolute;
    bottom: 64px;
    right: 103%;
    height: 23px;
    display: block;
    z-index: 1;
  }

  .grayBackground {
    padding-top: 12%;
    padding-bottom: 12%;
    padding: 12%;
  }

  .video-slide {
    max-width: 300px;
    margin: 0 auto;
    touch-action: pan-y;
    -webkit-overflow-scrolling: touch;
  }

  .video-slide iframe {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    touch-action: manipulation;
  }
}

/* tablet view */
@media (min-width: 769px) and (max-width: 1024px) {
  .testimonials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 73vh;
  }
  .grayBackground {
    padding-top: 12%;
    padding-bottom: 12%;
    padding: 18%;
    padding-top: 69px;
    padding-bottom: 75px;
  }
}

/* imac view */
@media (min-width: 4480px) {
  .testimonials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 102vh;
  }

  .grayBackground {
    background-color: #f8f8f8;
    padding: 20px;
    text-align: center;
    width: 100%;
    padding-top: 9%;
    padding-bottom: 8%;
  }

  .slick-slider {
    max-width: 100% !important;
    margin: 0 auto;
  }

  .slick-slide {
    display: flex !important;
    justify-content: center !important;
  }

  .slick-list {
    overflow: hidden !important;
  }

  .video-slide {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
  }

  .video-slide iframe {
    border-radius: 80px;
    width: 115vw !important;
    height: 59.375vw !important;
    max-width: 1429px;
    max-height: 849px;
  }

  .slick-dots li button:before {
    font-size: 33px !important;
    color: #f6edd4;
    margin-top: 23px;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 22px;
    padding: 0;
    cursor: pointer;
  }

  .testimonials-container .slick-dots {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .testimonials-container .slick-dots li {
    margin: 0 21px;
  }
}
