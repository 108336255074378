/* desktop view */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.paralax-space {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.counter-con {
  margin: 0;
  font-family: Arial, sans-serif;
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../assets/paralax.webp");
  min-height: 260px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-top: 90px;
}

.custom-parallax {
  position: relative;
}

.custom-parallax::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}



/* tablet second view */
@media (min-width: 1024px) {
  .custom-parallax::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

.counter-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
  url("../assets/paralax.webp"), url("../assets/paralax.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateZ(0);
  transition: transform 0.4s ease-out;
}

.countclass {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  margin-top: 134px;
  position: relative;
  z-index: 2;
}

.counter-icon {
  width: 70px;
  height: 72px;
  margin-left: 40px;
  margin-top: 98%;
  position: relative;
  z-index: 2;
}

.counters-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  /* margin-top: 7%; */
  margin-bottom: 5%;
  border-radius: 10px;
  position: relative;
  top: 50%;
}

.counter-item {
  display: inline-block;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  margin-top: 93px;
  margin-right: 17px;
}

.counter-label {
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
  font-size: 11px;
  position: relative;
  z-index: 2;
}

.counter-value {
  font-size: 24px;
  font-weight: bold;
}




/* mobile view */

@media (max-width: 767px) {
  .paralax-space {
    background-size: cover !important;
  }

  .overlay {
    display: none;
  }

  .custom-parallax .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }

  .counter-con {
    background-attachment: scroll;
    margin-top: 56px;
    background-size: cover;
    height: 62vh;
  }

  .Parallax {
    background-size: cover;
  }

  .counters-container {
    flex-direction: column;
    align-items: center;
    padding-top: 51px;
    margin-top: -6%;
  }

  .counter-label {
    font-weight: bold;
    margin-bottom: 26px;
    color: #fff;
    font-size: 12px;
  }

  .counter-icon {
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    margin-right: 230px;
    width: 54px;
    height: 60px;
  }

  .counter-item {
    margin-right: 0;
    text-align: center;
    margin-bottom: 20px;
    display: inline-block;
    align-items: center;
    /* margin: 0 17px; */
    /* padding: 10px; */
    border-radius: 10px;
    color: #fff;
    margin-top: -81px;
    margin-left: 41px;
  }

  .countclass {
    font-size: 27px !important;
    margin-top: 10px;
  }
}


/* tablet view */
@media (min-width: 769px) and (max-width: 1024px) {
  .counter-con {
    margin: 0;
    font-family: Arial, sans-serif;
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url("../assets/paralax.webp");
    min-height: 260px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 0px;
  }

  .counter-icon {
    width: 52px;
    height: 54px;
    margin-left: 30px;
    margin-right: 33px;
    margin-top: 129%;
  }

  .countclass {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    margin-top: 134px;
  }

  .counters-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 0%;
    margin-bottom: 10%;
    border-radius: 10px;
    position: relative;
    top: 50%;
    /* transform: translateY(-50%); */
  }
}

/* imac view */
@media (min-width: 4480px) {
  .countclass {
    font-size: 105px;
    margin-top: 150px;
    margin-right: 358px;
  }

  .comp {
    max-width: 166px;
    height: 103px;
    /* margin-left: 244px;  */
    margin-top: 213%;
  }

  .counter-icon {
    width: 166px;
    height: 198px;
    /* margin-left: 40px; */
    /* margin-right: 16px; */
    margin-top: 65%;
  }

  .counter-item {
    padding: 10px;
    margin-right: 119px;
    /* margin-top: 120px;  */
  }

  .counter-label {
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 162px;
    color: #fff;
  }

  .counters-container {
    display: flex;
    margin: 0 auto;
    margin-top: 3%;
    margin-bottom: 2%;
    border-radius: 10px;
    position: relative;
    top: 50%;
    max-width: 1200px;
    right: 28%;
   /* transform: translateY(-50%); */
  }

  .counter-con {
    margin: 0;
    font-family: Arial, sans-serif;
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      url("../assets/paralax.webp");
    min-height: 260px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: 290px;
  }
}
