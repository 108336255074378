/* desktop view */
.backimg {
  height: 624px;
  width: 100%;
}
.para {
  height: 157px;
  text-align: center;
  margin-top: 91px;
  font-size: 37px;
  font-weight: 200;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.index {
  height: 16px;
}
.fade-in-text {
  font-size: 36px;
  animation: fadeIn 3s;
}

.fade-out {
  font-size: 36px;
  animation: fadeout 3s;
}

.fon {
  font-size: 14px;
}

.icon {
  margin-top: 4px;
}

.para:not(:first-child) {
  opacity: 0;
}
.bold {
  font-weight: bold;
}
.background-container {
  position: relative;
  text-align: center;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* mobile view */
@media (max-width: 768px) {
  .para {
    height: 92px;
    font-size: 24px;
    margin-top: 60px;
  }

  .custom-padding {
    padding: 0.6rem;
  }
  .given {
    width: 135%;
  }

  .w-custom {
    width: 128%;
    gap: 2rem;
    padding-right: 95px;
  }

  .para .bold {
    font-weight: bold;
  }
  .backimg {
    height: 451px;
    width: 100%;
  }
}

/* tablet view */
@media (max-width: 1024px) {
  .para {
    font-size: 20px;
    margin-top: 50px;
  }
  .given {
    /* width: 135%; */
    margin-bottom: 103px;
  }
}

/* tablet view */
@media (min-width: 769px) and (max-width: 1024px) {
  .para {
    height: 92px;
    font-size: 24px;
    margin-top: 60px;
  }

  .lf {
    left: 18%;
    transform: translateY(-50%) !important;
  }

  .custom-padding {
    padding: 0.6rem;
  }

  .w-custom {
    width: 128%;
    gap: 2rem;
    padding-right: 95px;
  }

  .para .bold {
    font-weight: bold;
  }
  .backimg {
    height: 451px;
    width: 100%;
  }

  .searcb {
    margin-bottom: 51px;
    padding-right: 30px;
  }

  .given {
    width: 135%;
    margin-bottom: 55px;
  }
}

/* imac view */
@media (min-width: 4480px) {
  .fade-in-text {
    font-size: 90px;
    animation: fadeIn 3s;
  }

  .para {
    height: 157px;
    text-align: center;
    margin-top: 150px;
    padding-bottom: 300px;
    font-size: 80px;
    font-weight: 300;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    margin-bottom: 50px;
  }
  .backimg {
    height: 2088px;
    width: 100%;
  }

  .ss {
    height: 106px;
    width: 1699px;
    border-radius: 24px;
  }

  .iconhi {
    height: 41px;
  }

  .icing {
    font-size: 50px;
    padding: 33px;
    border-radius: 16px;
  }

  .search-input {
    height: 66px;
    width: 1399px;
    font-size: 41px;
    margin-top: 13px;
  }
}
