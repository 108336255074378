/* desktop css */
.achivments-container {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  justify-content: flex-start;
  margin: 0 76px;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.daschi{
  padding-bottom: 33px;
  padding-top: 13px;
}

.gray-square {
  width: 280px; 
  max-width: 100%; 
  height: 176px;
  background-color: #ccc;
  margin: 0 20px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 43px;
}

.logo-img {
  max-width: 52%;
  max-height: 38%;
}

.text {
  color: #766F6F;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 400;
}


/* mobile css */
@media (max-width: 767px) {
  .gray-square {
    width: 80%; 
    height: 160px;
    margin-right: 0;
  }

  .achi{
    padding-bottom: 36px;
    padding-top: 18px;
  }
  .achivments-container {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start;
    margin: 0 20px;
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  
}

/* tablet css */
@media (min-width: 769px) and (max-width: 1024px){
  .daschi{
    padding-bottom: 51px;
   }
}


/* imac css */
@media (min-width: 4480px) {
  .achivments-container {
    margin: 0 auto; 
    max-width: 80%;
    justify-content: center;
  }

  .gray-square {
    width: 811px;
    height: 522px;
    margin: 0 42px;
    border-radius: 112px;
  }

  .logo-img {
    max-width: 62%;
    max-height: 48%;
    height: 62%;
  }

  .text {
    font-size: 61px;
  }

  .daschi {
    padding-bottom: 50px; 
    padding-top: 70px; 
  }
 
    .Atopb{
    font-size: 6rem;
    margin-top: 5%;
    padding-bottom: 5%;
    }
    
}