/* desktop view */
.card {
  max-width: 17rem;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  margin: 20px;
  height: 21rem;
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}
.pg {
  padding-bottom: 23px;
  margin-top: 5%;
}

.desktophi {
  height: 94%;
}

.but {
  border-radius: 15px;
  margin-left: 24%;
}

.maindiv {
  margin-right: 5px;
}

.card img {
  width: 100%;
  height: auto;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.bigimg {
  height: 507px;
  margin-left: 72px;
}

.card-content {
  padding: 1.25rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #1f2937;
}

.card-description {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: #4b5563;
}

.read-more-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  background-color: #2563eb;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.heading {
  margin-top: 96px;
  margin-bottom: 95px;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}
.read-more-button:hover {
  background-color: #1d4ed8;
}

.arrow-icon {
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.5rem;
  stroke: currentColor;
  stroke-width: 2;
  fill: none;
}

.card {
  display: inline-block;
  width: 48%;
  margin: 1%;
}

.row {
  width: 100%;
  margin-bottom: 20px;
}

.card-container {
  overflow: hidden;
}


/* mobile view */
@media (max-width: 768px) {
  .merg {
    margin-right: 18px;
    height: 28rem;
    margin-left: 1rem;
    width: 92%;
  }

  .leflty {
    margin-left: 12px;
    margin-right: 6px;
  }

  .rightly {
    max-width: 23rem;
    margin-left: 12px;
  }
  .mside {
    margin-left: 43%;
  }
}


/* desktop view */
@media (min-width: 769px) and (max-width: 1024px) {
  .tabview {
    height: 75%;
    width: 99%;
  }

  .fonting {
    font-size: 11px;
  }
  .maindiv {
    margin-bottom: 37%;
  }
}

/* imac view */
@media (min-width: 4480px) {
  .ftopb {
    font-size: 6rem;
    margin-top: 7%;
    padding-bottom: 5%;
  }
  .imgw {
    max-width: 111rem;
    height: 131rem;
    margin-left: 17rem;
  }

  .leftside {
    margin-left: 32% !important;
  }

  .but {
    /* margin-left: 31%; */
    padding: 38px;
    font-size: 42px;
  }
  .forcards {
    height: 9.8vw;
    min-width: 100%;
  }
  .csfont {
    font-size: 36px;
    padding: 2.25rem;
    /* border-radius: 31px; */
    margin-top: 13px;
    border-radius: 15px;
  }
  .carding {
    max-width: 56rem;
    height: 59rem;
  }
  .f-sizing {
    font-size: 51px;
    line-height: 87px;
  }
}
