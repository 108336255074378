/* desktop view */
.custom-dropdown {
  position: relative;
}

.dropdown-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 21px;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #b6adad;
}

.unit-type-heading {
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  padding-right: 49px;
}

.dropdown-icon {
  height: 18px;
  width: auto;
  margin-right: 8px;
  margin-top: 4px;
  margin-left: 10px;
  filter: grayscale(100%);
}

.headingg {
  font-size: 12px;
  font-weight: bold;
  color: #f1833e;
}

.dropdown-button .border-element {
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
}

.dropdown-options {
  line-height: 15px;
  position: absolute;
  top: 111%;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  display: none;
  padding: 4px;
  right: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 35px;
}
.orange-radio {
  border-color: orange;
 
}

.dropdown-options label {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: #000;
}

.dropdown-options input[type="radio"] {
  margin-right: 8px;
  accent-color: #f26913;
}

.custom-dropdown.open .dropdown-options {
  display: flex;
}

.dropdown-options .commercial-sub-options {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.dropdown-options .commercial-sub-options label {
  margin-bottom: 10px;
  font-size: 15px;
}

/* mobile view */
@media (max-width: 767px) {
  .dropdown-icon {
    height: 18px;
    width: auto;
    margin-right: 62px;
    margin-top: 4px;
    margin-left: 10px;
    filter: grayscale(100%);
  }
  .dropdown-options {
    line-height: 19px;
    position: absolute;
    top: 92%;
    background-color: white;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    display: none;
    padding: 4px;
    right: 33px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 35px;
  }
  .dropdown-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    /* margin-right: 21px; */
    padding-top: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #b6adad;
    width: 134px;
  }
}

/* imac view */
@media (min-width: 4480px) {
  .dropdown-icon {
    height: 44px;
    width: auto;
    margin-right: 5px;
    margin-top: 4px;
    margin-left: 10px;
    filter: grayscale(100%);
  }
  .dropdown-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 21px;
    padding-top: 17px;
    font-size: 40px;
    font-weight: 400;
    color: #b6adad;
  }
  .dropdown-options input[type="radio"] {
    width: 25px;
    height: 26px; 
    margin-right: 34px; 
    transform: scale(1.5); 
    font-size: 25px; 
    accent-color: #f26913; 
  }

  .dropdown-options label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 46px;
  }
  .dropdown-options .commercial-sub-options label {
    margin-bottom: 10px;
    font-size: 45px;
  }

  .dropdown-options {
    line-height: 57px;
    position: absolute;
    top: 106%;
    background-color: white;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    display: none;
    padding: 16px;
    right: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 98px;
  }

  .headingg {
    font-size: 41px;
    font-weight: bold;
    color: #f1833e;
  }
}
