@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.slider-container {
  position: relative;
  width: 100%;
 
  margin: 0 auto; /* Center the slider container */
}

.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: relative;
  top: 50%;
  display: none;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #ffffff00;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slider-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.secondimg{
  width: 100vw;
  height: 68vh;
  margin-top: 20px;
}
/* CSS for over */
.over {
  position: relative;
 
}

.first{
  width: 100vw;
  margin-top: 20px;
}
.button.active {
  border-bottom: 2px solid white; /* Add a white underline for active buttons */
}

.over::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 96.6%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
  margin-top: 20px;

}

.learn-more-button {

  margin-top: 20px; /* Adjust the margin according to your design */
}

.learn-more-button button {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease;
  border-radius: 18px;
}



.bolding,
.over-text span {
  white-space: normal; /* Allow line breaks for the span element */
}

.bolding{
   font-weight: bold;
   font-family: 'Poppins', sans-serif;
}

.bol,
.over-text span {
  white-space: normal; /* Allow line breaks for the span element */
}

.bol{
 font-family: 'Poppins', sans-serif;
}



/* Additional styles for text on the over */
.over-text1 {
  position: absolute;
  top: 50%; /* Position text at the center vertically */
  left: 37%; /* Position text at the center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  color: white;
  font-size: 42px;
  /* display: flex; */
  justify-content: flex-end;
  white-space: nowrap; /* Prevent line breaks */
  text-align: left;
  /* font-weight: bold; */
  /* text-align: center; */
  font-weight: 300;
  
}

.over-text2 {
  position: absolute;
  top: 50%; /* Position text at the center vertically */
  left: 37%; /* Position text at the center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  color: white;
  font-size: 42px;
  /* display: flex; */
  justify-content: flex-end;
  white-space: nowrap; /* Prevent line breaks */
  text-align: left;
  /* font-weight: bold; */
  /* text-align: center; */
  font-weight: 300;
}

.firstover {
  position: absolute;
  top: 50%; /* Position text at the center vertically */
  left: 50%; /* Position text at the center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  width: 80%; /* Set a maximum width for the text to prevent overflow */
}

.buttons-container {
  position: absolute;
  top: 20px; /* Position the buttons 20px from the top of the image */
  right: 20px; /* Position the buttons 20px from the right of the image */
  display: flex;
  gap: 20px; /* Add some space between the buttons */
}

.button {
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.3s ease;
  margin-top: 20px;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* Add your existing styles here */

/* Tablet Styles (768px to 1024px) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .over-text1,
  .over-text2 {
    font-size: 29px; /* Adjust font size for tablets */
    line-height: 1.4; /* Adjust line height for tablets */
  }

  .buttons-container {
    top: 15px; /* Adjust top position of buttons for tablets */
    right: 15px; /* Adjust right position of buttons for tablets */
    gap: 15px; /* Adjust space between buttons for tablets */
  }

  .button {
    font-size: 18px; /* Adjust font size for tablets */
  }
  .bol {
    font-size: 26px; /* Adjust font size for tablets */
  }

  .over::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 95%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
    margin-top: 20px;
  
  }
}

/* Mobile Styles (up to 767px) */
@media screen and (max-width: 767px) {
  .over-text1,
  .over-text2 {
    font-size: 16px;
    line-height: 1.3;
    /* left: 20%; */
    right: 4%;
  }
  .mobile-break {
    display: none; /* Hide the <br> tag on larger screens */
  }

  .learn-more-button button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 10px;
    transition: all 0.3s ease;
    border-radius: 18px;
  }

.bol {
font-size: 16px;
white-space: nowrap;
  }
  

  .secondimg {
    width: 100%; /* Set the image width to cover the entire container */
    height: 113%; /* Set the image height to cover the entire container */
    object-fit: cover; /* Ensure the image covers the entire area while maintaining aspect ratio */
    margin-top: 20px;
  }

  .second{
    width: 100%; /* Set the image width to cover the entire container */
    height: 101%; /* Set the image height to cover the entire container */
    object-fit: cover; /* Ensure the image covers the entire area while maintaining aspect ratio */
    margin-top: 20px;
  }

  .buttons-container {
    top: 10px; /* Adjust top position of buttons for mobile devices */
    right: 10px; /* Adjust right position of buttons for mobile devices */
    gap: 10px; /* Adjust space between buttons for mobile devices */
  }

  .button {
    font-size: 16px; /* Adjust font size for mobile devices */
  }
  .over {
    position: relative;
    height: 72vw;
  }
  .over::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 22px;
  }

/* News.css */
/* Existing styles... */

/* Mobile Styles (up to 767px) */
@media screen and (max-width: 767px) {
  .over-1 {
    position: relative;
    height: 72vw; /* Adjust the height for the first image over */
  }

  .over-1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 105%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color for the first image */
    margin-top: 22px;
  }

  .over-2 {
    position: relative;
    height: 85vw; /* Adjust the height for the second image over */
  }

  .over-2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent red color for the second image */
    margin-top: 21px;
  }

  /* Rest of your existing styles for mobile view... */
}

  
}

@media (min-width: 769px) and (max-width: 1024px){
.secondimg {
  width: 100vw;
  height: 35vh;
  margin-top: 20px;
}
}

@media (min-width: 4480px) {
  .secondimg{
    width: 100vw;
    height: 57vh;
    margin-top: 20px;
  }

  .button {
    background-color: transparent;
    color: white;
    padding: 21px 95px;
    cursor: pointer;
    font-size: 73px;
    transition: all 0.3s ease;
}
.over::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black color */
  margin-top: 20px;

}
.over-text1 {
  position: absolute;
  top: 50%;
  left: 37%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 124px;
  /* display: flex; */
  justify-content: flex-end;
  white-space: nowrap;
  text-align: left;
  /* font-weight: bold; */
  /* text-align: center; */
  font-weight: 300;
}
.learn-more-button button {
  background-color: white;
  color: black;
  border: none;
  padding: 23px 53px;
  cursor: pointer;
  font-size: 60px;
  transition: all 0.3s ease;
  border-radius: 18px;
}

.over-text2 {
  position: absolute;
  top: 50%; /* Position text at the center vertically */
  left: 37%; /* Position text at the center horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  color: white;
  font-size: 124px;
  /* display: flex; */
  justify-content: flex-end;
  white-space: nowrap; /* Prevent line breaks */
  text-align: left;
  /* font-weight: bold; */
  /* text-align: center; */
  font-weight: 300;
}
}